<div class="payment-mode" [formGroup]="expenseForm">
  <div class="common-input input-container" [ngClass]="{'text-error': expenseForm.controls.paymentMode.touched && expenseForm.controls.paymentMode.invalid}">
    <label for="fyle-payment-mode">Payment Mode <span>*</span></label>
    <div class="payment-mode mt-2 w-full">

      <p-autoComplete #autoComplete
        inputId="paymentMode"
        formControlName="paymentMode"
        placeholder="Payment mode"
        inputStyleClass="w-full border-0 text-black font-600 pl-0"
        panelStyleClass="w-full"
        styleClass="w-full"
        field="name"
        [suggestions]="filteredPaymentModes"
        [dropdown]="true"
        [completeOnFocus]="true"
        (completeMethod)="filterPaymentModes($event)"
        (onSelect)="onOptionSelection($event)"
        (onKeyUp)="onInputKeyUp($event, autoComplete)">
        <ng-template let-paymentMode pTemplate="item" let-index="index">
          <div class="payment-mode-container text-dark-charcoal px-8-px py-8-px" [ngClass]="{'highlight': (selectedPaymentMode === '') ? (index === 0) : (paymentMode.name === selectedPaymentMode)}">
            <div class="paymentmode-title  font-600 py-4-px whitespace-pre-wrap break-words">
              <div>{{paymentMode.name}}</div>
              <div>{{paymentMode.balance}}</div>
            </div>
            <div class="payment-mode-content whitespace-pre-wrap break-words">
              <div>{{paymentMode.advancePurpose}}</div>
              <div class="payment-mode-reimbursable-status font-200">{{paymentMode.isReimbursable ? ' (Reimbursable)' : '(Non Reimbursable)'}}</div>
            </div>
            @if (index < filteredPaymentModes.length - 1) {
              <div class="payment-mode-divider"></div>
            }
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>

  @if (expenseForm.controls.paymentMode.touched && expenseForm.controls.paymentMode.invalid) {
    <div class="text-error">Please select a payment mode.</div>
  }
</div>
@if (invalidPaymentMode) {
  <app-alert-message
    [type]="alertMessageType.ERROR"
    [message]="'Insufficient balance in the selected account. Please choose a different payment mode.'">
  </app-alert-message>
}
@if (isOutstandingBalanceAvailable) {
  <app-alert-message
    [type]="alertMessageType.INFORMATION"
    [message]="'You have outstanding balance in your advance account(s).'">
  </app-alert-message>
}
