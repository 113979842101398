import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { LoaderComponent } from './components/loader/loader.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { BreakfastProvidedComponent } from './components/breakfast-provided/breakfast-provided.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { AddToReportComponent } from './components/add-to-report/add-to-report.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { SwitchOrgComponent } from './components/switch-org/switch-org.component';
import { LocalstorageAccessErrorComponent } from './components/localstorage-access-error/localstorage-access-error.component';
import { StatePipe } from './pipes/state.pipe';
import { ExpenseAmountComponent } from './components/expense-amount/expense-amount.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { ExpensePurposeComponent } from './components/expense-purpose/expense-purpose.component';
import { DateComponent } from './components/date/date.component';
import { DistanceComponent } from './components/distance/distance.component';
import { LocationComponent } from './components/location/location.component';
import { LocationAutocompleteComponent } from './components/location-autocomplete/location-autocomplete.component';
import { ProjectComponent } from './components/project/project.component';
import { PolicyViolatedComponent } from './components/policy-violated/policy-violated.component';
import { PaymentModeComponent } from './components/payment-mode/payment-mode.component';
import { FlightTravelClassComponent } from './components/flight-travel-class/flight-travel-class.component';
import { FileUploadFailedComponent } from './components/file-upload-failed/file-upload-failed.component';
import { SpendDateComponent } from './components/spend-date/spend-date.component';
import { TaxGroupComponent } from './components/tax-group/tax-group.component';
import { TravelClassComponent } from './components/travel-class/travel-class.component';
import { VendorComponent } from './components/vendor/vendor.component';
import { CategoryComponent } from './components/category/category.component';
import { CostCenterComponent } from './components/cost-center/cost-center.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { FyAutocompleteComponent } from './components/fy-autocomplete/fy-autocomplete.component';
import { CustomFieldValuePipe } from './pipes/custom-field-value.pipe';
import { HeaderComponent } from './components/header/header.component';
import { FyCurrencyPipe } from './pipes/fy-currency.pipe';
import { PolicyViolatedDescriptionComponent } from './components/policy-violated-description/policy-violated-description.component';
import { PolicyViolatedActionComponent } from './components/policy-violated-action/policy-violated-action.component';
import { CriticalPolicyViolatedDescriptionComponent } from './components/critical-policy-violated-description/critical-policy-violated-description.component';
import { CriticalPolicyViolatedComponent } from './components/critical-policy-violated/critical-policy-violated.component';
import { PendingDetailsComponent } from './components/pending-details/pending-details.component';
import { DependentFieldsComponent } from './components/dependent-fields/dependent-fields.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    AutoCompleteModule,
    CalendarModule,
    InputNumberModule,
    IconSpriteModule.forRoot({
      path: '../../assets/icon-sprite.svg',
    }),
  ],

  providers: [CurrencyPipe, FyCurrencyPipe],

  exports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    LoaderComponent,
    IconSpriteModule,
    StatePipe,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    AutoCompleteModule,
    CalendarModule,
    AddToReportComponent,
    AlertMessageComponent,
    SwitchOrgComponent,
    BreakfastProvidedComponent,
    ExpenseAmountComponent,
    CurrencyComponent,
    ExpensePurposeComponent,
    DateComponent,
    DistanceComponent,
    LocationComponent,
    LocalstorageAccessErrorComponent,
    ProjectComponent,
    PolicyViolatedComponent,
    PaymentModeComponent,
    FlightTravelClassComponent,
    FileUploadFailedComponent,
    SpendDateComponent,
    CategoryComponent,
    CostCenterComponent,
    CustomFieldsComponent,
    FyAutocompleteComponent,
    CustomFieldValuePipe,
    TravelClassComponent,
    VendorComponent,
    TaxGroupComponent,
    HeaderComponent,
    FyCurrencyPipe,
    CriticalPolicyViolatedComponent,
    CriticalPolicyViolatedDescriptionComponent,
    PolicyViolatedDescriptionComponent,
    PolicyViolatedActionComponent,
    PendingDetailsComponent,
    DependentFieldsComponent,
  ],
  declarations: [
    LoaderComponent,
    AddToReportComponent,
    AlertMessageComponent,
    StatePipe,
    SwitchOrgComponent,
    BreakfastProvidedComponent,
    LocalstorageAccessErrorComponent,
    ExpenseAmountComponent,
    CurrencyComponent,
    ExpensePurposeComponent,
    DateComponent,
    DistanceComponent,
    LocationComponent,
    LocationAutocompleteComponent,
    ProjectComponent,
    PolicyViolatedComponent,
    PaymentModeComponent,
    FlightTravelClassComponent,
    FileUploadFailedComponent,
    FyAutocompleteComponent,
    SpendDateComponent,
    TaxGroupComponent,
    TravelClassComponent,
    VendorComponent,
    CategoryComponent,
    CostCenterComponent,
    CustomFieldsComponent,
    FyAutocompleteComponent,
    CustomFieldValuePipe,
    HeaderComponent,
    FyCurrencyPipe,
    PolicyViolatedDescriptionComponent,
    PolicyViolatedActionComponent,
    CriticalPolicyViolatedDescriptionComponent,
    CriticalPolicyViolatedComponent,
    PendingDetailsComponent,
    DependentFieldsComponent,
  ],
})
export class SharedModule {}
