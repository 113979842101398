<div class="currency flex flex-wrap mt-7-px" [formGroup]="form">
  @if (isHomeCurrency) {
    <div class="currency-dropdown">
      <ng-template *ngTemplateOutlet="autoCompleteTemplate">
      </ng-template>
    </div>
    <div class="amount-container">
      <ng-template *ngTemplateOutlet="amountContainer">
      </ng-template>

    </div>
  } @else {
    <div class="currency-dropdown">
      <ng-template *ngTemplateOutlet="autoCompleteTemplate">
      </ng-template>
    </div>

    <div class="amount-container">
      <ng-template *ngTemplateOutlet="amountContainer">
      </ng-template>
    </div>
    @if (!isHomeCurrency && !options?.hideExchange) {
      <span class="currency-final-block mb-10-px mt-4-px">
        <span class="currency-exchange-rate inline-block text-dark-charcoal font-600 w-70-px">(at {{ exchangeRate }})</span>
        <span class="currency-home text-dark-charcoal inline-block font-600 w-10" [textContent]="homeCurrency"></span>

        <span class="final-amount-container">
          <p-inputNumber
            class="input-container"
            [ngClass]="{'text-error' : form.controls.amount.touched && form.controls.amount.invalid}"
            inputStyleClass="text-black font-400 text-16 w-full border-0 pb-10-px pt-2-px px-px"
            autocomplete="off"
            formControlName="amount"
            mode="decimal"
            id="p-final-amount"
            inputId="amount"
            [maxFractionDigits]="7"
            placeholder="Final amount"
            [useGrouping]="false"
            (onBlur)="onBlur()"
            (onInput)="onFinalAmountChange($event)">
          </p-inputNumber>
        </span>
    
      </span>
    }
  }

  <ng-template #amountContainer>
    <p-inputNumber
      inputStyleClass="text-black font-400 text-16 w-full border-0 pb-10-px pt-2-px px-px"
      autocomplete="off"
      class="input-container"
      formControlName="{{isHomeCurrency ? 'amount' : 'origAmount'}}"
      [ngClass]="{'text-error' : isHomeCurrency ? (form.controls.amount.touched && form.controls.amount.invalid) : (form.controls.origAmount.touched && form.controls.origAmount.invalid)}"
      mode="decimal"
      id="p-amount"
      inputId="{{isHomeCurrency ? 'amount' : 'origAmount'}}"
      [maxFractionDigits]="isHomeCurrency ? homeCurrencyFraction : foreignCurrencyFraction"
      placeholder="Enter amount"
      [useGrouping]="false"
      (onInput)="onAmountChange($event)">
    </p-inputNumber>

    @if (isHomeCurrency ? (form.controls.amount.touched && form.controls.amount.invalid) : (form.controls.origAmount.touched && form.controls.origAmount.invalid)) {
      <span class="text-error">Please enter amount</span>
    }

  </ng-template>

  <ng-template #autoCompleteTemplate>
    <app-autocomplete
      class="input-container"
      [ngClass]="{'text-error' : form.controls.currency.touched && form.controls.currency.invalid}"
      inputStyleClass="uppercase text-black font-400 w-full text-16 border-0 pb-10-px pt-2-px px-px"
      formControlName="currency"
      inputId="currency"
      fieldName="id"
      type="text"
      [options]="currencies"
      panelStyleClass="w-max"
      (selectedOption)="onCurrencySelection($event, isHomeCurrency)">
    </app-autocomplete>

    @if (form.controls.currency.touched && form.controls.currency.invalid) {
      <span class="text-error leading-1">Please select a valid currency</span>
    }

  </ng-template>
</div>