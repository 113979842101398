<div class="project" [formGroup]="expenseForm">
  <div class="common-input" [ngClass]="{'text-error': expenseForm.controls.project.touched && expenseForm.controls.project.invalid}">
    <label for="project">
      {{ projectField.field_name | titlecase }} 
      @if (projectField.is_mandatory) {
        <span>*</span>
      }
    </label>
    <div class="value">
      <p-autoComplete
        #autoComplete
        inputStyleClass="text-black font-600 w-full text-12 border-0"
        autocomplete="off"
        formControlName="project"
        id="p-project"
        inputId="project"
        type="text"
        field="display_name"
        [completeOnFocus]="true"
        [placeholder]="projectField.placeholder | titlecase"
        [suggestions]="filteredProjects"
        [dropdown]="true"
        (completeMethod)="filterProjects($event)"
        (onSelect)="onOptionSelection($event)"
        (onKeyUp)="onInputKeyUp($event, autoComplete)">

        <ng-template let-option pTemplate="option" let-idx="index">
          <div class="flex items-center px-8-px py-8-px" [ngClass]="{'highlight': selectedProject === '' ? (idx === 0) : (option.display_name === selectedProject)}">
            @if (option.isRecentlyUsed) {
              <svg-icon-sprite class="icon-recently-used" src="clock"></svg-icon-sprite>
            }
            <span class="option-text whitespace-pre-wrap break-all" [ngClass]="{'option-width' : option.isRecentlyUsed}">{{ option.display_name }}</span>
          </div>
          @if (option.isFirstInOption) {
            <div class="divider"></div>
          }
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
  @if (expenseForm.controls.project.touched && expenseForm.controls.project.invalid) {
    <span class="text-error">{{ selectProjectWarning }}</span>
  }
</div>

@if (showBillable && expenseForm.controls.project.valid && expenseForm.controls.project.value) {
  <div class="billable" [formGroup]="expenseForm">
    <div class="common-input">
      <label for="billable">Billable</label>
      <div class="value">
        <p-checkbox
          id="p-billable"
          inputId="billable"
          formControlName="is_billable"
          [binary]="true">
        </p-checkbox>
      </div>
    </div>
  </div>
}