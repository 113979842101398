<div class="localstorage-access-error text-dark-gray text-14 leading-1.2">
  <div class="storage-issue-header-warning">
    <svg-icon-sprite class="w-20 h-20 icon-error" src="warning-outline"></svg-icon-sprite>
    <span class="storage-issue-text">Oops! You may have blocked third party cookies</span>
  </div>

  <div class="p-5 text-center bg-white-smoke">
    <span class="storage-issue-text">Enable in just 3 steps!</span>
  </div>

  <div class="storage-issue-steps-container px-6-px py-20-px">
    <div class="feature-container">
      <svg-icon-sprite class="icon-feature" src="gear"></svg-icon-sprite>
      <div class="feature-details">
        <p class="feature-title mb-10-px flex items-center storage-issue-text">
          On the top right, click on <svg-icon-sprite class="icon-more" src="vertical-dots-menu"></svg-icon-sprite>
        </p>
        <ul class="feature-desc">
          <li class="list-item">
            Click on <strong class="header">Settings</strong>
          </li>
          <li class="list-item">
            Go to <strong class="header">Privacy and Security</strong>
          </li>
          <li class="list-item">
            Open <strong class="header">Cookies and other site data</strong>
          </li>
        </ul>
      </div>
    </div>

    <div class="feature-container">
      <svg-icon-sprite class="icon-feature" src="check-circle-outline"></svg-icon-sprite>
      <div class="feature-details">
        <p class="feature-title text-14">
          Select <strong class="header">Allow all cookies</strong>
          or <strong class="header">Block third-party cookies in Incognito</strong>
        </p>
      </div>
    </div>

    <div class="feature-container">
      <svg-icon-sprite class="icon-feature" src="arrow-clockwise"></svg-icon-sprite>
      <div class="feature-details">
        <p class="feature-title storage-issue-text">
          Come back and <a class="storage-issue-text" ng-click="vm.refreshParentWindow()" href>Refresh this page</a>
        </p>
      </div>
    </div>
  </div>

  <div class="storage-issue-header-steps bg-white-smoke p-8-px">
    <a class="flex items-center justify-center p-10-px underline" target="_blank" href="https://www.fylehq.com/help/en/articles/1664220-how-to-enable-3rd-party-cookies-in-chrome">
      Know more <svg-icon-sprite class="icon-navigate ml-5-px" src="open-in-new-tab"></svg-icon-sprite>
    </a>
  </div>
</div>