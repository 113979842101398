<div class="critical-policy-violation">
  <div class="critical-policy-header">Expense blocked</div>
  <div class="critical-policy-violation-container">
    <div class="critical-policy-action-heading">
      You cannot report this expense due to the following violation(s):
    </div>
    <app-critical-policy-violated-description [rules]="rules"></app-critical-policy-violated-description>
  </div>
  <div class="fyle-btn-block justify-evenly">
    <p-button type="button" label="Cancel" class="btn-cancel" (click)="onCancel()"></p-button>
    <p-button type="submit" label="Continue" class="btn-save" (click)="onSave()"></p-button>
  </div>
</div>


