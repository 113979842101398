import { Injectable } from '@angular/core';
import { ExtendedOrgUser } from '@core/models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { DataTransformService } from './data-transform.service';

// TODO: Add type here
@Injectable({
  providedIn: 'root',
})
export class OrgUserService {
  constructor(private apiService: ApiService, private dataTransformService: DataTransformService) {}

  getCurrent(): Observable<ExtendedOrgUser> {
    return this.apiService
      .get('/eous/current')
      .pipe(map((eou) => this.dataTransformService.unflatten(eou))) as Observable<ExtendedOrgUser>;
  }

  getEou(id: string) {
    return this.apiService.get('/eous/' + id).pipe(map((data) => this.dataTransformService.unflatten(data as any)));
  }
}
